<template>
  <div class="batch-report__add">
    <div class="batch-report__add-icon">
      <v-icon x-large color="rgba(0, 0, 0, 0.38)">
        mdi-note-plus-outline
      </v-icon>
    </div>
    <div class="batch-report__add-text text-body-2">
      <p>There is no Batch Report yet.</p>
      <p>To write it, press the button below.</p>
    </div>
    <v-btn rounded color="primary" @click="$emit('add-report')">
      <v-icon left> mdi-plus </v-icon>
      Add report notes and attachment
    </v-btn>
  </div>
</template>

<script>
export default {
  name: "AddBatchReport",
};
</script>
