<template>
  <v-select
    :value="value"
    :items="options"
    :loading="loading"
    :menu-props="menuProps"
    :label="label"
    multiple
    outlined
    clearable
    hide-details
    @click:clear="$emit('change', null)"
    @change="$emit('change', $event)"
  >
    <template #prepend-item>
      <div class="clinical-codes-multiselect__search">
        <v-list-item>
          <v-list-item-content class="pt-2 pb-0">
            <v-text-field
              placeholder="Search case"
              prepend-inner-icon="mdi-magnify"
              dense
              outlined
              clearable
              hide-details
              @input="onSearch"
            />
          </v-list-item-content>
        </v-list-item>
        <v-divider class="mt-2"></v-divider>
      </div>
    </template>

    <template v-slot:selection="data">
      <v-chip v-if="data.index < 2" label>
        {{ data.item.text }}
      </v-chip>
      <span v-if="data.index === 2">...</span>
    </template>
  </v-select>
</template>

<script>
import { debounce } from "lodash";
import { getCases } from "@/services/cases";

const menuProps = {
  bottom: true,
  offsetY: true,
  left: true,
};

export default {
  name: "CasesMultiselect",

  props: {
    value: {
      type: Array,
      default: () => [],
    },
    service: {
      type: String,
      default: () => "",
    },
    label: {
      type: String,
      default: () => "",
    },
  },

  data() {
    return {
      loading: false,
      cases: [],
      menuProps,
    };
  },

  computed: {
    options() {
      if (!this.cases.length) return [];
      return this.cases.map((value) => {
        if (value) {
          return {
            value: value._id,
            text: value.caseReference,
          };
        } else {
          return null;
        }
      });
    },
  },

  mounted() {
    this.fetch();
  },

  methods: {
    getCases,
    async fetch(search) {
      try {
        this.loading = true;
        const query = {};

        if (search?.length) {
          query.filter = {
            caseReference: { $regex: search, $options: "i" },
            ...query.filter,
          };
        }

        const { data } = await this.getCases(query);
        this.cases = [...data];
      } catch (e) {
        this.$notify({
          type: "error",
          text: e?.message || JSON.stringify(e),
        });
      } finally {
        this.loading = false;
      }
    },
    onSearch: debounce(function (search) {
      this.fetch(search);
    }, 800),
    onRemove({ value }) {
      const cases = this.value.filter((name) => value !== name);
      this.$emit("change", cases);
    },
    addOption(value) {
      this.cases.push(value);
    },
  },
};
</script>
