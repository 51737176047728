<template>
  <section class="d-flex flex-column align-start">
    <h2 class="text-h6 font-weight-bold pb-3">Batch Filters</h2>

    <v-container v-if="isInitialFiltersParsed" class="batches pa-0 pb-4" fluid>
      <v-row v-if="filterType === BatchTypes.audit" dense>
        <v-col cols="3">
          <TimeDataPicker
            ref-name="menu1"
            :date="filters.caseDate"
            label="Case Date"
            @change="handleFilterChange($event, 'caseDate')"
          />
        </v-col>

        <v-col class="d-flex" cols="3">
          <v-select
            :v-model="filters.locationName"
            :items="filterOptions.locationName"
            label="Location"
            :menu-props="{ bottom: true, offsetY: true }"
            multiple
            clearable
            outlined
            hide-details
            @change="handleFilterChange($event, 'locationName')"
          >
            <template v-slot:selection="{ index }">
              <div
                v-if="filters.locationName && index === 0"
                class="d-flex flex-start align-center"
                style="width: 100%"
              >
                <span class="text-truncate">
                  {{ filters.locationName.join(", ") }}
                </span>
              </div>
            </template>
          </v-select>
        </v-col>
      </v-row>
      <v-row v-if="filterType === BatchTypes.specialAudit" dense>
        <v-col class="d-flex" cols="3">
          <SearchInput
            :value="filters.prescriptions"
            label="Prescriptions"
            @change="handleFilterChange($event, 'prescriptions')"
          />
        </v-col>

        <v-col class="d-flex" cols="3">
          <ClinicalCodesMultiselect
            :value="filters.clinicalCodes"
            :return-object="true"
            @change="handleFilterChange($event, 'clinicalCodes')"
          />
        </v-col>

        <v-col class="d-flex" cols="2">
          <v-select
            v-if="serviceOption"
            :value="serviceOption"
            :items="[`${serviceOption}`]"
            outlined
            disabled
            hide-details
          />
        </v-col>

        <v-col class="d-flex" cols="2">
          <RangePicker
            :value="filters.age"
            @change="handleFilterChange($event, 'age')"
          />
        </v-col>

        <v-col class="d-flex" cols="2">
          <v-select
            :v-model="filters.sex"
            :items="filterOptions.sex"
            label="Gender"
            :menu-props="{ bottom: true, offsetY: true }"
            multiple
            clearable
            outlined
            hide-details
            @change="handleFilterChange($event, 'sex')"
          >
            <template v-slot:selection="{ index }">
              <div
                v-if="filters.sex && index === 0"
                class="d-flex flex-start align-center"
                style="width: 100%"
              >
                <span class="text-truncate">
                  {{ filters.sex.join(", ") }}
                </span>
              </div>
            </template>
          </v-select>
        </v-col>

        <v-col class="d-flex" cols="3">
          <v-select
            :v-model="filters.locationName"
            :items="filterOptions.locationName"
            label="Location"
            :menu-props="{ bottom: true, offsetY: true }"
            multiple
            clearable
            outlined
            hide-details
            @change="handleFilterChange($event, 'locationName')"
          >
            <template v-slot:selection="{ index }">
              <div
                v-if="filters.locationName && index === 0"
                class="d-flex flex-start align-center"
                style="width: 100%"
              >
                <span class="text-truncate">
                  {{ filters.locationName.join(", ") }}
                </span>
              </div>
            </template>
          </v-select>
        </v-col>

        <v-col class="d-flex" cols="3">
          <v-select
            :value="filters.caseType"
            :items="filterOptions.caseType"
            :menu-props="{ bottom: true, offsetY: true }"
            label="Case type"
            clearable
            hide-details
            outlined
            @change="handleFilterChange($event, 'Case type')"
          ></v-select>
        </v-col>

        <v-col cols="3">
          <TimeDataPicker
            ref-name="menu1"
            :date="filters.caseDate"
            label="Case Date"
            @change="handleFilterChange($event, 'caseDate')"
          />
        </v-col>

        <v-col cols="3">
          <BatchCasesAmountPicker
            :value="casesAmount.value"
            :type="casesAmount.type"
            ref="caseAmountPicker"
            :batch-type="filterType"
            @change="handleCasesAmountUpdate"
          />
        </v-col>
      </v-row>
      <v-row v-if="filterType === BatchTypes.docReview">
        <v-col class="d-flex" cols="4">
          <CliniciansMultiselect
            ref="cliniciansMultiselect"
            :value="filters.clinicians"
            :service="service"
            :selector="getClinicians"
            :label="'Clinicians'"
            @change="handleFilterChange($event, 'clinicians')"
          />
        </v-col>

        <v-col>
          <v-select
            v-if="serviceOption"
            :value="serviceOption"
            :items="[`${serviceOption}`]"
            outlined
            disabled
            hide-details
          />
        </v-col>

        <v-col class="d-flex" cols="3">
          <v-select
            :value="filters.locationName"
            :items="filterOptions.locationName"
            label="Location"
            :menu-props="{ bottom: true, offsetY: true }"
            multiple
            clearable
            outlined
            hide-details
            @change="handleFilterChange($event, 'locationName')"
          >
            <template v-slot:selection="{ index }">
              <div
                v-if="filters.locationName && index === 0"
                class="d-flex flex-start align-center"
                style="width: 100%"
              >
                <span class="text-truncate">
                  {{ filters.locationName.join(", ") }}
                </span>
              </div>
            </template>
          </v-select>
        </v-col>

        <v-col class="d-flex" cols="3">
          <v-select
            :value="filters.caseType"
            :items="filterOptions.caseType"
            :menu-props="{ bottom: true, offsetY: true }"
            label="Case type"
            clearable
            hide-details
            outlined
            @change="handleFilterChange($event, 'caseType')"
          ></v-select>
        </v-col>

        <v-col cols="3">
          <TimeDataPicker
            ref-name="menu1"
            :date="filters.caseDate"
            label="Case Date"
            @change="handleFilterChange($event, 'caseDate')"
          />
        </v-col>
        <v-col class="d-flex" cols="3">
          <CasesMultiselect
            ref="casesMultiselect"
            :value="filters.cases"
            :label="'Cases'"
            @change="handleFilterChange($event, 'cases')"
          />
        </v-col>
        <v-col cols="2">
          <BatchCasesAmountPicker
            :value="casesAmount.value"
            :type="casesAmount.type"
            ref="caseAmountPicker"
            :batch-type="filterType"
            @change="handleCasesAmountUpdate"
          />
        </v-col>
      </v-row>
      <v-row v-if="filters.clinicalCodes" dense class="pr-1 pl-1">
        <v-chip
          v-for="code in filters.clinicalCodes"
          :key="code.value"
          class="mr-2 mb-2"
          close-icon="mdi-close-circle"
          close
          label
          @click:close="onRemoveClinicalCode(code.value)"
        >
          {{ code.text }}
        </v-chip>
      </v-row>
    </v-container>

    <v-card
      v-if="isAccountAIFeature && filterType === BatchTypes.docReview"
      class="w-100 pa-0 pb-4 mb-3"
    >
      <v-text-field
        class="mt-3 ml-3 mr-3"
        :value="valueAIPrompt"
        label="Write your criteria and AI will generate a batch for you"
        placeholder="Enter your text"
        outlined
        clearable
        @input="onAIFilterInput($event)"
      />
      <v-btn
        :disabled="!valueAIPrompt"
        :loading="aiLoading"
        class="ml-3"
        color="primary"
        rounded
        normal
        @click="onAIGenerateClick"
      >
        Generate with AI
      </v-btn>
    </v-card>

    <v-btn
      :disabled="isGenerateButtonDisabled"
      :loading="loading"
      color="primary"
      outlined
      rounded
      normal
      variant="elevated"
      @click="onGenerateClick"
    >
      Generate batch
    </v-btn>
  </section>
</template>

<script>
import { BatchTypes } from "@/misc/constants";
import SearchInput from "../controls/SearchInput.vue";
import TimeDataPicker from "../controls/TimeDataPicker.vue";
import RangePicker from "../controls/RangePicker.vue";
import ClinicalCodesMultiselect from "../controls/ClinicalCodesMultiselect.vue";
import BatchCasesAmountPicker from "../controls/BatchCasesAmountPicker.vue";
import CliniciansMultiselect from "../controls/CliniciansMultiselect.vue";
import CasesMultiselect from "../controls/CasesMultiselect.vue";
import { getClinicians } from "@/services/users";
import { getAIFilters } from "@/services/AIAssistant";
import { getCases } from "../../services/cases";
import { useUserStore } from "@/stores/user";
import { mapState } from "pinia";

export default {
  name: "GenerateBatchBlock",

  components: {
    SearchInput,
    TimeDataPicker,
    RangePicker,
    ClinicalCodesMultiselect,
    BatchCasesAmountPicker,
    CliniciansMultiselect,
    CasesMultiselect,
  },

  props: {
    filterType: {
      type: String,
      required: true,
    },
    filterOptions: {
      type: Object,
      default: () => {},
    },
    service: {
      type: String,
      default: null,
    },
    serviceName: {
      type: String,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabledButton: {
      type: Boolean,
      default: false,
    },
    initialFilters: {
      type: Object,
      default: null,
    },
    caseType: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      BatchTypes,
      aiLoading: false,
      filters: {
        caseDate: null,
        prescriptions: null,
        clinicalCodes: null,
        age: null,
        sex: null,
        locationName: null,
        caseType: null,
        clinicians: null,
        cases: null,
      },
      casesAmount: { value: null, type: "percent" },
      filtersBody: null,
      isModalVisible: false,
      isInitialFiltersParsed: false,
      selectedCodes: null,
      valueAIPrompt: null,
    };
  },

  computed: {
    ...mapState(useUserStore, ["currentAccount"]),
    isGenerateButtonDisabled() {
      return (
        (this.filterType === BatchTypes.audit &&
          (!this.filters.caseDate || !this.filters.caseDate?.length)) ||
        this.disabledButton
      );
    },
    serviceOption() {
      return this.$route.query?.serviceName || this.serviceName;
    },
    isAccountAIFeature() {
      const { _id } = this.currentAccount;
      return _id === process.env.VUE_APP_ACCOUNT_AI_FEATURE;
    },
  },

  mounted() {
    this.parseInitialFilters();
    this.isInitialFiltersParsed = true;
    this.buildFiltersQuery();
  },

  methods: {
    getClinicians,
    getAIFilters,
    getCases,
    handleFilterChange(value, key) {
      this.filters[key] =
        Array.isArray(value) && !value?.length
          ? null
          : typeof value === "string" && !value?.length
          ? null
          : value;
      this.buildFiltersQuery();
    },
    buildFiltersQuery() {
      this.filters["clinicalService"] = this.service;
      if (Object.values(this.filters).some((val) => !!val === true)) {
        const filters = {};
        Object.keys(this.filters).forEach((key) => {
          if (this.filters[key]) {
            switch (key) {
              case "caseDate": {
                const [first, second] = this.filters[key];
                const startDate = new Date(first);
                const endDate = second
                  ? new Date(second)
                  : new Date(startDate.getTime() + 86400000);
                filters[key] = { $gte: startDate, $lt: endDate };
                break;
              }
              case "prescriptions":
                filters[key] = { $regex: this.filters[key], $options: "i" };
                break;
              case "age": {
                const data = this.filters[key]
                  .split("-")
                  .map((el) => Number(el));
                if (data[1]) {
                  filters[key] = { $gte: data[0], $lte: data[1] || 100 };
                } else {
                  filters[key] = { $gte: data[0], $lte: data[0] };
                }
                break;
              }
              case "clinicalCodes": {
                const ids = this.filters[key].map((code) => code.value);
                filters[key] = { $in: ids };
                break;
              }
              case "cases": {
                filters["_id"] = { $in: this.filters[key] };
                break;
              }
              case "sex":
              case "locationName":
              default:
                filters[key] = this.filters[key];
            }
          }
        });
        this.filtersBody = filters;
      }
    },
    onGenerateClick() {
      const filters =
        Object.values(this.filters).some((val) => !!val === true) &&
        this.filtersBody
          ? this.filtersBody
          : null;
      const casesAmount = this.casesAmount.value ? this.casesAmount : null;
      this.$emit("generate", { filters, casesAmount });
    },
    handleMenuUpdate(value) {
      this.$emit("change", value);
    },
    handleClear() {
      this.$emit("change", null);
    },
    handleCasesAmountUpdate(value) {
      this.casesAmount = value;
    },
    parseInitialFilters() {
      if (!this.initialFilters || this.filterType === BatchTypes.docReview)
        return;
      for (const key in this.initialFilters) {
        if (this.filters[key] !== undefined && this.initialFilters[key]) {
          switch (key) {
            case "age":
              this.filters[key] = [
                this.initialFilters[key].min,
                this.initialFilters[key].max,
              ].join("-");
              break;
            case "clinicalCodes": {
              const codes =
                Array.isArray(this.initialFilters[key]) &&
                this.initialFilters[key]?.length
                  ? this.initialFilters[key]
                  : null;
              const parsedCodes = codes
                ? codes.map((code) => ({ name: code, value: code }))
                : null;
              this.filters[key] = parsedCodes;
              break;
            }
            default: {
              this.filters[key] = this.initialFilters[key];
            }
          }
        }
      }
      this.buildFiltersQuery();
    },
    onRemoveClinicalCode(codeId) {
      const codes = this.filters.clinicalCodes.filter(
        ({ value }) => value !== codeId
      );
      this.filters.clinicalCodes = codes?.length ? codes : null;
      this.buildFiltersQuery();
    },
    onAIFilterInput(input) {
      this.valueAIPrompt = input;
    },
    async onAIGenerateClick() {
      this.aiLoading = true;
      const filtersResponse = await this.getAIFilters(this.valueAIPrompt);
      for await (const key of Object.keys(filtersResponse)) {
        await this.generateAIFilters(filtersResponse, key);
      }
      this.buildFiltersQuery();
      this.onGenerateClick();
      this.aiLoading = false;
    },
    async generateAIFilters(filtersResponse, key) {
      switch (key) {
        case "clinicians": {
          if (!filtersResponse.clinicians) {
            this.filters.clinicians = null;
            return;
          }
          const { data } = await this.getClinicians({
            filter: {
              name: { $in: filtersResponse.clinicians },
            },
          });
          this.filters.clinicians = [];
          data &&
            data.forEach((value) => {
              this.$refs.cliniciansMultiselect.addOption(value);
              this.filters.clinicians.push(value._id);
            });
          return;
        }

        case "casesAmount":
          if (!filtersResponse[key]) {
            this.casesAmount = { value: null, type: "percent" };
            return;
          }

          if (filtersResponse[key].includes("%")) {
            this.casesAmount = {
              type: "percent",
              value: filtersResponse[key].split("%")[0],
            };
          }

          if (filtersResponse[key].includes("case")) {
            this.casesAmount = {
              type: "quantity",
              value: filtersResponse[key].split(" case")[0],
            };
          }
          return;

        case "locationName":
          if (!filtersResponse.locationName) {
            this.filters.locationName = null;
            return;
          }
          this.filters.locationName = [];
          this.filters.locationName.push(filtersResponse[key][0]);
          return;

        case "caseReference": {
          if (!filtersResponse[key]) {
            this.filters.cases = null;
            return;
          }
          const query = {
            filter: {
              caseReference: { $in: filtersResponse[key] },
            },
          };

          const { data } = await this.getCases(query);

          this.filters.cases = [];

          data &&
            data.forEach((value) => {
              this.$refs.casesMultiselect.addOption(value);
              this.filters.cases.push(value._id);
            });
          return;
        }

        default:
          return;
      }
    },
  },
};
</script>
