<template>
  <div class="batch-cases-amount-picker">
    <v-text-field
      v-model="inputValue"
      outlined
      type="number"
      label="Amount"
      hide-details
      hide-spin-buttons
      class="batch-cases-amount-picker__input"
      @input="handleDataChange"
    >
    </v-text-field>

    <v-select
      v-model="inputType"
      :items="selectItems"
      :menu-props="{ bottom: true, offsetY: true }"
      outlined
      hide-details
      class="batch-cases-amount-picker__select"
      @change="handleDataChange"
    />
  </div>
</template>

<script>
import { BatchTypes } from "@/misc/constants";

const AmountTypes = {
  all: "all",
  percent: "percent",
  quantity: "quantity",
};

const SpecialAuditItems = [
  {
    value: AmountTypes.percent,

    text: "Pct, %",
  },
  {
    value: AmountTypes.quantity,
    text: "Qty",
  },
  {
    value: AmountTypes.all,
    text: "All cases",
  },
];

const DocReviewItems = [
  {
    value: AmountTypes.percent,
    text: "Pct, %",
  },
  {
    value: AmountTypes.quantity,
    text: "Qty",
  },
];

export default {
  name: "BatchCasesAmountPicker",

  props: {
    value: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: AmountTypes.percent,
    },
    batchType: {
      type: String,
      required: true,
    },
  },

  data() {
    return { inputValue: this.value, inputType: this.type };
  },

  computed: {
    selectItems() {
      return this.batchType === BatchTypes.specialAudit
        ? SpecialAuditItems
        : DocReviewItems;
    },
  },

  methods: {
    handleDataChange() {
      const data = {
        value: this.inputValue,
        type: this.inputType,
      };
      this.$emit("change", data);
    },
  },
};
</script>

<style lang="scss">
.batch-cases-amount-picker {
  width: 100%;
  display: flex;

  &__input {
    width: 40%;
    border-right: none;
    border-radius: 4px 0px 0px 4px;
  }

  &__select {
    width: 60%;
    border-radius: 0px 4px 4px 0px;
  }
}
</style>
